import React from "react";
import ReactECharts, { EChartsOption } from "echarts-for-react";
import axios from "axios";
import moment from "moment";
import { IFundHoldingsRecord } from "./FundHoldings";

export interface IFundHoldingsCompositionProps {
  fund: string;
  date?: moment.Moment;
}

export interface IFundHoldingsCompositionState {
  values: any[];
  sum?: number;
}

class FundHoldingsComposition extends React.Component<
  IFundHoldingsCompositionProps,
  IFundHoldingsCompositionState
> {
  constructor(props: IFundHoldingsCompositionProps) {
    super(props);

    this.state = {
      values: [],
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: IFundHoldingsCompositionProps) {
    if (prevProps.fund !== this.props.fund) {
      this.fetchData();
    } else if (prevProps.date !== this.props.date) {
      this.fetchData();
    }
  }

  fetchData() {
    axios
      .get(
        `https://ark-funds-data-api.kytse.com/api/v1/fund-holdings/${
          this.props.fund
        }?${
          this.props.date ? `date=${this.props.date.format("YYYY-MM-DD")}&` : ""
        }`
      )
      .then((response) => {
        // handle success
        return response.data;
      })
      .then((data: IFundHoldingsRecord[]) => {
        let values = data
          .map((data) => {
            return { value: data.weight, name: data.ticker };
          })
          .sort((a, b) => {
            return b.value - a.value;
          })
          .slice(0, 10);

        let sum = values
          .map((value) => Number(value.value))
          .reduce((prev, next) => prev + next);
        let remaining = 100 - sum;

        values.push({ name: "[others]", value: remaining });

        this.setState({
          values: values,
          sum: sum,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  tooltipFormatter = (param: any) => {
    return `<div style="margin: 0px 0 0; line-height: 1">
    <div style="margin: 0px 0 0; line-height: 1">
        ${param.marker}
        <span
        style="font-size: 14px; color: #666; font-weight: 400; margin-left: 2px"
        >${param.name}</span
      ><span
        style="
          float: right;
          margin-left: 20px;
          font-size: 14px;
          color: #666;
          font-weight: 900;
        "
        >${param.value}%</span
      >
      <div style="clear: both"></div>
    </div>
    <div style="clear: both"></div>
  </div>`;
  };

  options: () => EChartsOption = () => {
    return {
      title: {
        text: "Fund Holding Breakdown",
        subtext: `% of asset in Top 10 holdings: ${this.state.sum}%`,
        left: "center",
      },
      tooltip: {
        trigger: "item",
        formatter: this.tooltipFormatter,
      },
      series: [
        {
          type: "pie",
          radius: "50%",
          data: this.state.values.map((value) => {
            return { value: value.value, name: value.name };
          }),
        },
      ],
    };
  };

  render() {
    return (
      <div>
        <ReactECharts option={this.options()} notMerge={true} />
      </div>
    );
  }
}

export default FundHoldingsComposition;

import React from "react";
import axios from "axios";
import moment from "moment";
import { Button, Input, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import {
  FilterConfirmProps,
  FilterDropdownProps,
} from "antd/lib/table/interface";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

export interface IFundHoldingsProps {
  fund: string;
  date?: moment.Moment;
}

interface IFundHoldingsState {
  data: any[];
  searchText: React.Key;
  searchedColumn: string;
}

export interface IFundHoldingsRecord {
  date: string;
  fund: string;
  company: string;
  ticker: string;
  cusip: string;
  shares: number;
  market_value: number;
  weight: number;
  [key: string]: string | number;
}

class FundHoldings extends React.Component<
  IFundHoldingsProps,
  IFundHoldingsState
> {
  constructor(props: IFundHoldingsProps) {
    super(props);

    this.state = {
      data: [],
      searchText: "",
      searchedColumn: "",
    };
  }

  fetchData() {
    axios
      .get(
        `https://ark-funds-data-api.kytse.com/api/v1/fund-holdings/${
          this.props.fund
        }?${
          this.props.date ? `date=${this.props.date.format("YYYY-MM-DD")}&` : ""
        }`
      )
      .then((response) => {
        // handle success
        var data = response.data.map((row: IFundHoldingsRecord) => ({
          key: row.cusip,
          date: moment(row.date).format("YYYY-MM-DD"),
          company: row.company,
          ticker: row.ticker,
          weight: row.weight,
        }));

        this.setState({
          data: data,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  getColumnSearchProps = (dataIndex: string) => {
    return {
      filterDropdown: (props: FilterDropdownProps) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search ${dataIndex}`}
            value={props.selectedKeys[0]}
            onChange={(e) =>
              props.setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() =>
              this.handleSearch(props.selectedKeys, props.confirm, dataIndex)
            }
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() =>
                this.handleSearch(props.selectedKeys, props.confirm, dataIndex)
              }
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => this.handleReset(props.clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (
        value: string | number | boolean,
        record: IFundHoldingsRecord
      ) =>
        record[dataIndex]
          ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes((value as string).toLowerCase())
          : false,
      render: (text: any) =>
        this.state.searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText.toString()]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        ),
    };
  };

  handleSearch = (
    selectedKeys: any[],
    confirm: { (param?: FilterConfirmProps | undefined): void; (): void },
    dataIndex: string
  ) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters: (() => void) | undefined) => {
    if (clearFilters) clearFilters();
    this.setState({ searchText: "" });
  };

  columns: ColumnsType<IFundHoldingsRecord> = [
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a: IFundHoldingsRecord, b: IFundHoldingsRecord) =>
        a.date.localeCompare(b.date),
    },
    {
      title: "Company",
      dataIndex: "company",
      ...this.getColumnSearchProps("company"),
      sorter: (a: IFundHoldingsRecord, b: IFundHoldingsRecord) =>
        a.company.localeCompare(b.company),
    },
    {
      title: "Ticker",
      dataIndex: "ticker",
      ...this.getColumnSearchProps("ticker"),
      sorter: (a: IFundHoldingsRecord, b: IFundHoldingsRecord) =>
        a.ticker.localeCompare(b.ticker),
    },
    {
      title: "Weight",
      dataIndex: "weight",
      sorter: (a: IFundHoldingsRecord, b: IFundHoldingsRecord) =>
        a.weight - b.weight,
      defaultSortOrder: "descend",
    },
  ];

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: IFundHoldingsProps) {
    if (prevProps.fund !== this.props.fund) {
      this.fetchData();
    } else if (prevProps.date !== this.props.date) {
      this.fetchData();
    }
  }

  render() {
    return (
      <Table
        title={() => "Fund All Holdings"}
        columns={this.columns}
        dataSource={this.state.data}
      />
    );
  }
}

export default FundHoldings;

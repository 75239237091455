import "./App.css";
import Dashboard from "./Dashboard";
import { PageHeader } from "antd";
// import FundHoldings from "./FundHoldings";

function App() {
  return (
    <div className="App">
      <PageHeader
        className="site-page-header"
        title="Fund Overview"
      />
      <Dashboard />
    </div>
  );
}

export default App;

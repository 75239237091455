import React from "react";
import ReactECharts, { EChartsOption } from "echarts-for-react";
import axios from "axios";
import moment from "moment";
import { IFundHoldingsRecord } from "./FundHoldings";
import { Space, Input, Select } from "antd";
import { valueType } from "antd/lib/statistic/utils";

const { Option } = Select;

export interface ITopHoldingsTrendProps {
  fund: string;

  top: number;
  date?: moment.Moment;
}

export interface ITopHoldingsTrendState {
  dates: any[];
  values: any[];

  interval: string;
}

class TopHoldingsTrend extends React.Component<
  ITopHoldingsTrendProps,
  ITopHoldingsTrendState
> {
  constructor(props: ITopHoldingsTrendProps) {
    super(props);

    this.state = {
      dates: [],
      values: [],

      interval: "week",
    };
  }

  handleIntervalInputChange = (e: valueType) => {
    this.setState({ interval: e as string }, () => {
      this.fetchData();
    });
  };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: ITopHoldingsTrendProps) {
    if (prevProps.fund !== this.props.fund) {
      this.fetchData();
    } else if (prevProps.date !== this.props.date) {
      this.fetchData();
    } else if (prevProps.top !== this.props.top) {
      this.fetchData();
    }
  }

  fetchData() {
    axios
      .get(
        `https://ark-funds-data-api.kytse.com/api/v1/fund-holdings/${
          this.props.fund
        }/top-holdings?${
          this.state.interval ? `interval=${this.state.interval}&` : ""
        }${
          this.props.date ? `date=${this.props.date.format("YYYY-MM-DD")}&` : ""
        }${`top=${this.props.top}&`}`
      )
      .then((response) => {
        // handle success
        return response.data;
      })
      .then((data: IFundHoldingsRecord[]) => {
        let map: Map<string, IFundHoldingsRecord[]> = new Map();
        let dates: string[] = [];

        data.forEach((holding: IFundHoldingsRecord) => {
          let value = map.get(holding.ticker);
          if (value === undefined) {
            map.set(holding.ticker, [holding]);
          } else {
            map.set(holding.ticker, [holding].concat(value));
          }

          if (!dates.includes(holding.date)) {
            dates.push(holding.date);
          }
        });

        return { map, dates };
      })
      .then(({ map, dates }) => {
        dates.sort((a, b) => {
          return moment(a).unix() - moment(b).unix();
        });

        let values: any[] = [];

        map.forEach((value, key) => {
          let data: (number | null)[] = [];

          dates.forEach((date) => {
            let weight: number | null = null;

            value.forEach((value) => {
              if (value.date === date) {
                weight = value.weight;
              }
            });

            data.push(weight);
          });

          values.push({
            name: key,
            data: data,
          });
        });

        this.setState({
          dates: dates.map((date) => moment(date).format("YYYY-MM-DD")),
          values: values,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  toolTipFormatter = (params: any[]) => {
    params.sort((a, b) => {
      return b.data - a.data;
    });
    return `<div style="margin: 0px 0 0; line-height: 1">
    <div style="margin: 0px 0 0; line-height: 1">
      <div
        style="font-size: 14px; color: #666; font-weight: 400; line-height: 1"
      >
        ${params[0].name}
      </div>
      <div style="margin: 10px 0 0; line-height: 1">
            ${params
              .map((param: { value: any; marker: any; seriesName: any }) => {
                return `<div style="margin: 10px 0 0; line-height: 1; ${
                  param.value ? "" : "display: none"
                }">
                <div style="margin: 0px 0 0; line-height: 1">
                  ${param.marker}
                  <span
                  style="
                    font-size: 14px;
                    color: #666;
                    font-weight: 400;
                    margin-left: 2px;
                  ">${param.seriesName}</span>
                  <span
                  style="
                    float: right;
                    margin-left: 20px;
                    font-size: 14px;
                    color: #666;
                    font-weight: 900;
                  ">${param.value ? `${param.value}%` : "-"}</span>
                  <div style="clear: both"></div>
                </div>
                <div style="clear: both"></div>
              </div>`;
              })
              .join("")}
        <div style="clear: both"></div>
      </div>
      <div style="clear: both"></div>
    </div>
    <div style="clear: both"></div>
  </div>`;
  };

  options: () => EChartsOption = () => {
    return {
      title: {
        text: `Fund Top 5 Holdings Trend`,
        left: "center",
      },
      xAxis: {
        data: this.state.dates,
        boundaryGap: false,
        type: "category",
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: "{value}%",
        },
      },
      series: this.state.values.map((value) => {
        return {
          name: value.name,
          data: value.data,
          type: "line",
        };
      }),
      tooltip: {
        trigger: "axis",
        formatter: this.toolTipFormatter,
      },
    };
  };

  render() {
    return (
      <div>
        <ReactECharts option={this.options()} notMerge={true} />
        <Space>
          <Input.Group>
            <Select
              defaultValue={this.state.interval}
              onChange={this.handleIntervalInputChange}
            >
              <Option value="week">1w</Option>
              <Option value="month">1m</Option>
              <Option value="quarter">3m</Option>
            </Select>
          </Input.Group>
        </Space>
      </div>
    );
  }
}

export default TopHoldingsTrend;

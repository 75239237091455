import React from "react";
import { Space, Input, Select, DatePicker } from "antd";
import { valueType } from "antd/lib/statistic/utils";
import TopHoldingsTrend from "./TopHoldingsTrend";
import FundHoldingsComposition from "./FundHoldingsComposition";
import FundHoldings from "./FundHoldings";
import moment from "moment";

const { Option } = Select;

export interface IDashboardProps {}

export interface IDashboardState {
  fund: string;
  date?: moment.Moment;
}

class Dashboard extends React.Component<IDashboardProps, IDashboardState> {
  constructor(props: IDashboardProps) {
    super(props);

    this.state = {
      fund: "ARKK",
    };
  }

  handleFundInputChange = (e: valueType) => {
    this.setState({ fund: e as string });
  };

  handleDateChange = (date: moment.Moment | null) => {
    this.setState({
      date: date ? date : undefined,
    });
  };

  disabledDate = (currentDate: moment.Moment) => {
    if (currentDate.endOf("day") > moment().endOf("day")) {
      return true;
    } else if (currentDate.isBefore(moment("2021-04-01"))) {
      return true;
    } else {
      return false;
    }
  };

  componentDidMount() {}

  componentDidUpdate(prevProps: IDashboardProps) {}

  render() {
    return (
      <div>
        <Space direction="vertical">
          <Space>
            <Input.Group>
              <Select
                defaultValue={this.state.fund}
                onChange={this.handleFundInputChange}
              >
                <Option value="ARKK">ARKK</Option>
                <Option value="ARKQ">ARKQ</Option>
                <Option value="ARKW">ARKW</Option>
                <Option value="ARKG">ARKG</Option>
                <Option value="ARKF">ARKF</Option>
                <Option value="ARKX">ARKX</Option>
              </Select>
            </Input.Group>
            <DatePicker
              onChange={this.handleDateChange}
              allowClear={false}
              disabledDate={this.disabledDate}
              defaultValue={moment()}
            />
          </Space>
          <FundHoldings fund={this.state.fund} date={this.state.date} />
          <FundHoldingsComposition
            fund={this.state.fund}
            date={this.state.date}
          />
          <TopHoldingsTrend
            fund={this.state.fund}
            date={this.state.date}
            top={5}
          />
        </Space>
      </div>
    );
  }
}

export default Dashboard;
